import { Link } from "react-router-dom";
import { BASE_URL_IMG } from "../Constant";

function ReviewCartItem({ product }) {
  return (
    <div className="d-flex">
      <div className="flex-shink-0">
        <img
          className="rounded"
          src={BASE_URL_IMG+product.iconURL}
          width={80}
          height={80}
          alt="Product image."
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="flex-grow-1 ms-3 h-100">
        <div className="vstack">
          <a className="text-dark text-decoration-none">{product.title}</a>

          <h6 className="mb-0">{"₹ " + product.price}</h6>
          <h6 className="mb-0">{"$ " + product.priceInDollar}</h6>
        </div>
      </div>
    </div>
  );
}

export default ReviewCartItem;
