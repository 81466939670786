import { Link } from "react-router-dom";


 function PlaceholderBannar() {
    return (
     
        <div className="container  placeholder-glow mt-5 ">
          <div
            className="ratio "
            style={{ "--bs-aspect-ratio": "80%", maxHeight: "550px" }}
          >
            <img
              className="placeholder disabled w-100 h-100 rounded-5"
              alt=""
              // src={props.image}
              //key={props.image}
            />
          </div>
        </div>
 

      // <div className="container-fluid mt-5 gap-2" aria-hidden="true">
      //   <div className="row">
      //     <div className="col">
      //       <div className=" card mt-5 " aria-hidden="true">
      //         {/* <div
      //       style={{ height: 550 }}
      //       className="placeholder disabled "
      //       aria-hidden="true"
      //     >

      //     </div> */}
      //         <img
      //           className="rounded bg-dark  rounded-5"
      //           alt=""
      //           src="./../Vector.png"
      //           style={{ height: 200 }}

      //         />
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }

  function ProductListCard() {
    return (
      <div class="card    border border-dark  mt-5  ">
        <div className="row   ">
          <div className="col-4">
            <div className=" card   placeholder-glow" aria-hidden="true">
              <div
                // style={{ width: "18rem" }}
                style={{ height: 160 }}
                className="placeholder disabled "
                aria-hidden="true"
              ></div>
            </div>
          </div>

          <div className="col-8 ">
            <p class="card-text placeholder-glow py-3">
              <span class="placeholder col-7 "></span>
              <span class="placeholder col-2  ms-4 text-muted "></span>
              <span class="placeholder col-6"></span>
              <span class="placeholder col-8"></span>
            </p>

            <Link
              // href="#"
              // tabindex="-1"
              class="btn btn-primary disabled placeholder col-2 position-absolute bottom-2 end-0 me-3 "
            ></Link>
          </div>
        </div>
      </div>
    );
  }


  function ProductGridCard(){


    return (
      <div class="card mt-5  placeholder-glow " aria-hidden="true">
        <div
          style={{ height: 160 }}
          className="placeholder  disabled rounded-4 "
          aria-hidden="true"
        ></div>

        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder col-7"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-6"></span>
            <span class="placeholder col-8"></span>
          </p>
          <Link class="btn btn-primary disabled placeholder col-6  "></Link>
        </div>
      </div>
    );
  };


  function AboutUs(){
    return (
      <div class="card   placeholder-glow  border border-dark  mt-5  ">
        <div className="row  text-center ">
          <div className="col-md-4">
            <div className=" card  " aria-hidden="true">
              <div
                style={{ height: 300 }}
                className="placeholder disabled  "
                aria-hidden="true"
              ></div>
            </div>
          </div>

          <div className="col-md-8 ">
            <p class="card-text placeholder-glow py-3">
              <span class="placeholder col-7 "></span>
              <span class="placeholder col-2  ms-4 text-muted "></span>
              <span class="placeholder col-6"></span>
              <span class="placeholder col-8"></span>
            </p>
          </div>
        </div>
      </div>
    );
  }


  function Line (){
    
    return (
      <div className=" placeholder-glow">
        <span class="placeholder  placeholder-glow col-4 mt-5"></span>
      </div>
    );
    
    
   
  };

function Placeholder() {
 return (
   <>
    
     <div className=" pt-5">
       <PlaceholderBannar />
     </div>
     <div className="container">
       <Line />
       <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 g-4   ">
         <ProductListCard />
         <ProductListCard />
       </div>
       <Line />
       <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 px-md-6 pt-1">
         <ProductGridCard />
         <ProductGridCard />
         <ProductGridCard />
         <ProductGridCard />
       </div>
       <Line />
       <div className="row row-cols-1 ">
         <AboutUs />
       </div>
       <Line />
       <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 px-md-6 pt-1">
         <ProductGridCard />
         <ProductGridCard />
         <ProductGridCard />
         <ProductGridCard />
       </div>
     </div>
   </>
 );
}
export default Placeholder;
