import http from "../services/http-common";

class LoginService {
  getAll() {
    return http.get("login");
  }

  get(id) {
    return http.get(`login/${id}`);
  }

  create(data) {
    // Request interceptors for API calls
    return http.post("login", data);
  }

  update(id, data) {
    return http.put(`login/${id}`, data);
  }
  getCartCount(data,access_token) {
    http.defaults.headers.common['Authorization'] = 'Bearer '+access_token;
    return http.post("getCartCount", data);
  }

  delete(id) {
    return http.delete(`login/${id}`);
  }

  deleteAll() {
    return http.delete(`login`);
  }

  findByTitle(title) {
    return http.get(`login?title=${title}`);
  }
}

export default new LoginService();