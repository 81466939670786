function TermsAndConditions (){



    return (
      <>
        <div class="row g-0 pt-5">
          <div class="col p-5 m-5">
            <div class="card-body">
              <div className=" d-none d-md-block">
                <h1 className="text-dark h"> Terms And Conditions:</h1>
                <div className="vstack gap-1">
                  {/* <small className="d-flex fs-5 text-dark text-opacity-75 gap-2">
                  <FontAwesomeIcon
                    icon={["fa-solid", "fa-browser"]}
                    className="mt-1"
                  />
                  <div className="h5">shreesarvshakti.com</div>
                </small> */}
                </div>
              </div>
              <p class="fs-10 lh-base bold">
                ShreeSarvShakti is a web e-trade designed to offer a smooth way
                for our clients to online puja services.
              </p>
              <p class="fs-10">
                Service Providers: We use different third events to provide
                payment processing on our web page. When you transact or
                location an order on our website, we are able to proportion the
                shipping address with the credit card range with the fee gateway
                provider as essential for the Third-party celebration to provide
                that carrier. These third-parties events are prohibited from the
                usage of your in my opinion identifiable statistics for any
                other purpose.
              </p>
              <p class="fs-10">
                We no longer sell, lease, proportion, change, or give away any
                of your non-public information without your permission except to
                the monetary institution that methods your credit card
                transactions. We reserve the proper to disclose your in my
                opinion identifiable statistics as required by law and while we
                believe that disclosure is necessary to protect our rights and/
                or observe a judicial intending, court docket order, or prison
                manner served on our Website. shreesarvshakti uses cookies to
                offer the services, to deliver content material precisely for
                your pastimes, to keep your password so that you do not ought to
                re-enter it each time you visit distinct pages on our website,
                and for different purposes. Please word that cookies are used
                best to keep in mind facts dispatched for your laptop from
                ShreeSarvShakti.com.
              </p>

              <p class="fs-10">
                <h4> Third-Party </h4> Cookies: Some of our business partners
                (e.g., advertisers) use cookies on our website. We have no
                access to or management over these cookies. This private
                announcement covers using cookies by way of shreesarvshakti
                handiest and does not cover the use of cookies via any
                advertisers.
              </p>
              <p class="fs-10">
                If we decide to exchange our privateness policy, we are able to
                put up those adjustments to this privacy declaration, the home
                web page, and different locations we deem suitable so that you
                are aware of what statistics we gather, how we use them, and
                beneath what occasions if any, we disclose it.
              </p>
              <p class="fs-10">
                We reserve the proper to alter this privacy statement at any
                time, so please assess it often. If we make cloth changes to
                this coverage, we will notify you right here, with the aid of
                electronic mail, or with the aid of a note on our domestic web
                page.
              </p>
              <p class="fs-10">
                <h4> Terms & Conditions : </h4> By the usage of shreesarvshakti
                for any intention by any means, you believe the company law that
                you'll now not strive to utilize different consumer names,
                passwords, or account records without authorization from us.
              </p>
              <p class="fs-10">
                Under copyright law, the organization will now not permit
                posting, copying, downloading, uploading, transmission,
                retransmission, distribution, redistribution, guide,
                republication, recompilation, disassembling, or different
                amendment or commercial exploitation of any proprietary material
                made to be had thru ShreeSarvShakti. All the contents/text and
                pictures used on www.shreesarvshakti.com are assets of Shree
                Sarv Shakti. The organization is not affiliated with websites
                that may be related to Shree Sarv Shakti thru hypertext. The
                organization has not manipulated over and isn't answerable for,
                the content on any linked site. The linked sites are provided
                for user comfort handiest and you get right of entry to them at
                your very own risk. We will attend to customer service requests
                within 3 operating days.
              </p>

              {/* <p class="fs-10">
                <h4> Return, Refund & Cancellation Policy : </h4> At
                shreesarvshakti , our intention is to make certain your whole
                pleasure together with your buy. If for whatever reason, you're
                disappointed along with your buy, you may go back to us within
                10 days of receipt. Claims for lacking items or objects damaged
                in transit ought to be acquired within two enterprise days of
                receipt of products. You have the option of exchanging the
                object(s) or receiving money back for the overall quantity of
                the object purchased (much less shipping charge). Refunds on
                again items may be issued in the identical payment form as
                tendered at the time of buy. If the price became made by credit
                card or Paypal, as soon as we acquire the product we can credit
                score your account.
              </p> */}

              <p class="fs-10">
                <h4> return or alternate an item : </h4>
                us an email at shaktishreesarv@gmail.com before starting up the
                go back. We recommend you to send through a dependable courier
                or velocity put up with a tracking quantity. Return transport
                prices are the obligation of the consumer. We aren't liable for
                lost or damaged programs. Pack the product in a narrow p.c. With
                bubble packing in order that they may be secure throughout
                transit
              </p>
              <p class="fs-10">
                <h4> Security Policy : </h4>
                We actively support you to be careful when acknowledging any
                email request for sensitive personal information. Before signing
                in, or filing any data please test that the URL asking for the
                information is proper shreesarvshakti URLs will constantly
                show shreesarvshakti as the domain of the facts amassing URL.
                Before submitting credit score card numbers over the Internet,
                make certain that you are using a relaxed server. The starting
                of the net deal within your browser window has to be "https://"
                and now not "http://". For relaxed server pages, you should
                additionally see a "lock" icon at the lowest of the browser.
                shreesarvshakti collects data on the Secure Server tested
                through Verisign. Help us hold our customers safe. If you've got
                any doubt whether an electronic mail is from ShreeSarvShakti,
                forward the message to shaktishreesarv@gmail.com right away.
                Don't adjust the concerned line or ahead of the message as an
                attachment - doing so makes it extra hard for us to react
                quickly. The employer will not be responsible for any damages of
                any kind springing up from the use of this site, which includes
                but no longer confined to direct, indirect, unique, and
                consequential damages.
              </p>

              <p class="fs-10">
                <h4> Delivery Policy : </h4>
                We ship orders the world over
                (USA/UK/Canada/Australia/Asia/Africa/Europe) All the gadgets
                purchased from shreesarvshakti are shipped within 1-3 days of
                purchase via FedEx/UPS/DHL/Bluedart/Indian Postal services. The
                transport fees rely on the extent of the gadgets and will be
                brought to your cart whilst you checkout. The transport time is
                3-5 days internationally. 
              </p>
              <p class="fs-10">
                <h4> Clarifications : </h4>
                If you encounter any information in our catalog or website that
                is incorrect, please tell us at shreesarvshakti.com In case any
                explanation is needed on any of the factors cited above, you can
                reach us by sending a mail atshreesarvshakti.com.
              </p>
            </div>
          </div>
        </div>

        {/* // <div className="Auth-form-container">
        //   <form className="Auth-form">
        //     <div className="Auth-form-content">
        //       <h3 className="Auth-form-title"> TermsAndConditions</h3>
        //       <a className="fs-4"> Privacy Policy: </a>
        //       <p>
        //         {" "}
        //         <h4> ShreeSarvShakti </h4> is a web e-trade designed to offer a
        //         smooth way for our clients to store online. Shaligram Shala
        //         (shaligram.com) has a large collection of religious items like
        //         Shaligram Shila, Rudraksha (1 to 21 mukhi), yantras & maha
        //         yantras, parad beads & parad shivling, gemstones, online puja
        //         services, dwarka shila, kailash shila, giri goverdhan shila,
        //         shankh, murtis & temples and much more. We also send various
        //         attractive gift items along with your desired items (which you
        //         buy). Must visit shaligram.com to buy religious products.{" "}
        //       </p>
        //       Service Providers: We use different third events to provide
        //       logistics and payment processing on our web page. When you
        //       transact or location an order on our website, we are able to
        //       proportion the shipping address with the logistics issuer and
        //       credit card range with the fee gateway provider as essential for
        //       the Third-party celebration to provide that carrier. These
        //       third-parties events are prohibited from the usage of your in my
        //       opinion identifiable statistics for any other purpose.
        //     </div>
        //   </form>
        // </div> */}
      </>
    );
}

export default TermsAndConditions;