import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState, useEffect } from "react";
import { SESSION_ID, CUSTOMER_ID ,BASE_URL,ChangeOrderStatus,ACCESS_TOKEN, DEBUG_LOG_ENABLED,} from "../Constant";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ReviewCartItem from "./ReviewCartItem";
import GetOrderService from "./GetOrderService";
import { Alert } from "bootstrap";
import swal from "sweetalert";


function OrderHistoryItemDetail({ id, product, cancel, addressData }) {
  const navigate = useNavigate();
  //orderStatus
  //1. Order PLACED
  //2. Order CANCELED
  //3. Order COMPLETED
  const cancelOrder = async () => {
    try {
      const canceleData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: product.id,
            orderStatus: 2,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if(DEBUG_LOG_ENABLED){
      console.log("canceleData : " + canceleData);
      }

      // const response = await GetOrderService.changeOrderStatus(canceleData);
      let response = await axios.post(BASE_URL + ChangeOrderStatus, canceleData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });


      if(DEBUG_LOG_ENABLED){
      console.log(response.data);
      }
      swal(response.data.respData.message);
      
      navigate("/orderhistory");
      // history.push("/")
    } catch (err) {
      swal(err);
      if(DEBUG_LOG_ENABLED){
      console.log(err);
      }
    }
  };

  return (
    <div className="card border-0 shadow-sm mb-3">
      <div className="card-header py-3 bg-white">
        <div className="row">
          <div className="col d-flex">
            <span className="fw-semibold h5 my-auto">Order ID: {id}</span>
          </div>
          <div className="col-auto">
            {product.orderStatus === 2 ? null : product.orderStatus ===
              3 ? null : (
              <button
                className="btn btn-sm btn-outline-danger"
                onClick={cancelOrder}
              >
                Cancel order
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row gx-2 gy-3">
          <div className="col-md-5">
            <h6 className="fw-bold">Shipping Address</h6>
            <div className="vstack text-dark small">
              <span className="h6">{addressData[0]?.name}</span>
              <span>
                {addressData[0]?.addressLine1 +
                  "" +
                  addressData[0]?.addressLine2}
              </span>
              <span>
                {addressData[0]?.city +
                  " " +
                  addressData[0]?.state +
                  " " +
                  addressData[0]?.country +
                  " " +
                  addressData[0]?.pincode}
              </span>
              <span>{"Mobile No: " + addressData[0]?.mobileNo}</span>
            </div>
          </div>
          <div className="col-md-4">
            <h6 className="fw-bold">Payment Method</h6>
            <div className="text-success">
              {cancel ? (
                <>
                  <span className="fw-bold">
                    <FontAwesomeIcon
                      icon={["fas", "money-bill-wave"]}
                      size="lg"
                    />
                  </span>
                  <span className="ms-2 small">Cash on delivery</span>
                </>
              ) : (
                <>
                  {/* <span className="fw-bold">
                    <FontAwesomeIcon icon={["fab", "cc-visa"]} size="lg" />
                  </span> */}
                  <span className="ms-2 small">{product.paymentId}</span>
                </>
              )}
            </div>
            <div>
              {product.currency === "USD"
                ? "Subtotal: " + "$" + product.amount
                : "Subtotal: " + "₹" + product.amount}
            </div>

            <div className="fw-semibold">
              {product.currency === "USD"
                ? "Subtotal: " + "$" + product.amount
                : "Total: " + "₹" + product.amount}
            </div>
          </div>
          <div className="col-md-3">
            <h6 className="fw-bold">Status</h6>
            <div className={cancel ? "text-danger" : "text-success"}>
              <span className="fw-semibold">
                {product.orderStatus === 2
                  ? "CANCELLED"
                  : product.orderStatus === 3
                  ? "COMPLETED"
                  : product.orderStatus === 0
                  ? "PAYMENT PENDING"
                  : product.orderStatus === 1
                  ? "PLACED"
                  : product.orderStatus === -1
                  ? "PAYMENT FAILED"
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <hr className="text-muted" />
        <div className="row row-cols-1 row-cols-md-2 g-3">
          {product?.details?.map((it) => (
            <div className="col">
              <ReviewCartItem product={it} />
            </div>
          ))}
          ;
        </div>
      </div>
      <div className="card-footer small border-0 py-3 text-muted">
        {/* Order Date: {moment(Date(product.date)).format("DD-MMM-YYYY")} */}
        Order Date & Time :{product.date}:{product.startTime}
      </div>
    </div>
  );
}

export default OrderHistoryItemDetail;
