import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import QR from "../images/QR.jpeg"
export default function Qrpay() {
  const paypalAmount = localStorage.getItem("price");
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const paymentupinumbar = () => {
    localStorage.setItem("UpiOderId", confirmPassword);
    navigate("/ordersuccess");
  };

  return (
    // <div class="container">
    //   <div class="row">
    //     <div class="col">

    //       <div class="text-center ">
    //         <img
    //           src="https://drive.google.com/uc?id=1nd5H1ldSjgy5K323ycHbed554kSNlYRe"
    //           class="rounded mx-auto d-block"
    //           alt=""
    //         />
    //       </div>
    //     </div>
    //     <div class="col">Column</div>

    //   </div>
    // </div>
    <section className="vh-100" style={{ backgroundColor: "#0d6efd" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5  d-md-block">
                  <img
                    src={QR}
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <i
                          className="fas fa-cubes fa-2x me-3"
                          style={{ color: "#ff6219" }}
                        />

                        <div class="row">
                          <span className="h3 p-2 fw-bold mb-0">
                            {" Amount  :    " + paypalAmount + "/-"}
                          </span>
                          <span className="h3 p-2 fw-bold mb-0">
                            For bank transfers
                          </span>
                          <div class="col">
                            Name<br></br>A/C No.<br></br>Bank<br></br>IFSC
                            <br></br>{" "}
                          </div>
                          <div class="col">
                            Raghvendra Pandey<br></br>318801001122<br></br>ICICI
                            Bank<br></br>ICIC0003188
                            <br></br>{" "}
                          </div>
                        </div>
                      </div>
                      <h5
                        className="  fw-bold mb-3 pb-3"
                        style={{ letterSpacing: 1 }}
                      >
                        Enter your bank or upi transaction id
                      </h5>
                      <div className="form-outline mb-4">
                        <input
                          //   type="numbar"
                          id="form2Example17"
                          className="form-control form-control-lg"
                          placeholder="ex. T23081719645895369"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {/* <label className="form-label" htmlFor="form2Example17">
                          upi no
                        </label> */}
                      </div>
                      {/* <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form2Example27"
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form2Example27">
                          Password
                        </label>
                      </div> */}
                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-primary btn-lg btn-primary"
                          type="button"
                          onClick={() => paymentupinumbar()}
                        >
                          Continue
                        </button>
                      </div>
                      {/* <a className="small text-muted" href="#!">
                        Forgot password?
                      </a> */}
                      {/* <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                        Don't have an account?
                        <a href="#!" style={{ color: "#393f81" }}>
                          Register here
                        </a>
                      </p>
                      <a href="#!" className="small text-muted">
                        Terms of use.
                      </a>
                      <a href="#!" className="small text-muted">
                        Privacy policy
                      </a> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
