import http from "../services/http-common";

class RemoveCartItemService {
  getAll() {
    return http.get("removeFromCart");
  }

  get(id) {
    return http.get(`removeFromCart/${id}`);
  }

  create(data) {
    return http.post("removeFromCart", data);
  }

  update(id, data) {
    return http.put(`removeFromCart/${id}`, data);
  }

  delete(id) {
    return http.delete(`removeFromCart/${id}`);
  }

  deleteAll() {
    return http.delete(`removeFromCart`);
  }

  findByTitle(title) {
    return http.get(`removeFromCart?title=${title}`);
  }
}

export default new RemoveCartItemService();
