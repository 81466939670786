import React from "react";
import axios from "axios";

import { useLocation } from "react-router-dom";


export default function Phonpe(url) {
 
  const location = useLocation();
  const { dataUrl } = location.state || {};



  return (
 
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: 750 }}
    >
      <div className="p-2 m-2  text-white shadow rounded-2">
        <iframe
          className="justify-content-center"
          src={dataUrl}
          style={{ border: 0, width: "100%", height: 600, alignSelf: "center" }}
        ></iframe>
      </div>
    </div>
  );
}







//   const dateData = JSON.stringify({
//    merchantId: "MERCHANTUAT",
//   merchantTransactionId: "MT7850590068188104",
//   merchantUserId: "MUID123",
//   amount: 10000,
//   redirectUrl: "https://webhook.site/redirect-url",
//   redirectMode: "REDIRECT",
//   callbackUrl: "https://webhook.site/callback-url",
//   mobileNumber: "9999999999",
//   paymentInstrument: {
//     "type": "PAY_PAGE"
//   }
// })
 

//   const oldfetchData = async () => {
//     const options = {
//       method: "POST",
//       headers: {
//         accept: "application/json",
//         "Content-Type": "application/json",
//         "X-VERIFY": sebdataSHA256,
//       },
//       body: JSON.stringify({
//         request: payload,
//       }),
//     };

//    //fetch("https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay", options)
//  fetch("https://api.phonepe.com/apis/hermes/pg/v1/pay", options)
//    .then((response) => response.json())
//    .then((response) => alert(response))
//    .catch((err) => alert("ERROE------" + err));
//   };

  // const fetchDatas = async () => {
  //   const postData = {
  //     request: payload,
  //   };

  //   const axiosConfig = {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       "X-VERIFY": sebdataSHA256,
  //     },
  //   };

  //   try {
  //     const response = await axios.post(
  //     //  "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",
  //       " https://api.phonepe.com/apis/hermes/pg/v1/pay",
  //       postData,
  //       axiosConfig
  //     );
  //     alert(response.data);
  //   } catch (error) {
  //     alert(error);
  //     //console.error(error);
  //   }
  // };





