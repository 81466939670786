import axios from "axios";
import { ACCESS_TOKEN } from "../Constant";
export default axios.create({
  baseURL: "https://shreesarvshakti.com/shreeapi-v1/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    "Access-Control-Allow-Headers": "Content-Type",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
  },
});
