import { useEffect } from "react";
import CustomerReview from "../landing/CustomerReview";

const Reviews = () => {
    useEffect(() => {
        // Scroll to the top of the page whenever this component is rendered
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="mt-5 pt-4">
            <h1 className="px-3 fs-3 pt-4 pb-3" style={{ fontFamily: 'sans-serif' }}>Customer Reviews</h1>
            <CustomerReview />
        </div>
    )
}
export default Reviews;