import http from "../services/http-common";

class SignUpService {

  saveCustomer(data) {
    return http.post("addCustomer", data);
  }

  updateCustomer(id, data) {
    return http.put(`addCustomer/${id}`, data);
  }
}

export default new SignUpService();