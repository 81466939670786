import React from 'react';


const Privacypolicy = () => {
  return (
    <div class="container">
      <div className="mt-5" style={{ padding: 10 }}>
        <h1 className="text-center mb-4">Privacy Policy</h1>
        <p>
          Thank you for visiting Shree Sarv Shakti's website. This Privacy
          Policy explains how we collect, use, and disclose information about
          you when you visit our website or use our services. We respect your
          privacy and are committed to protecting your personal information.
        </p>

         <h5 className='fw-bold '>Information We Collect</h5>
        <p>
          When you visit our website or use our services, we may collect certain
          information about you, including:
        </p>
        <ul>
          <li>
            Personal Information: Such as your name, email address, phone
            number, and any other information you provide to us voluntarily.
          </li>
          <li>
            Usage Information: Such as your IP address, browser type, operating
            system, and browsing behavior on our website.
          </li>
          <li>
            Cookies: We may use cookies and similar tracking technologies to
            collect information about your interactions with our website.
          </li>
        </ul>

         <h5 className='fw-bold '>How We Use Your Information</h5>
        <p>
          We may use the information we collect for various purposes,
          including::
        </p>
        <ul>
          <li>
            To provide you with the services you request, such as online Puja
            booking or online chatting services.
          </li>
          <li>
            To communicate with you, respond to your inquiries, and provide
            customer support.
          </li>
          <li>
            To improve our website and services, and to personalize your
            experience. To comply with legal obligations and enforce our terms
            of service.
          </li>
        </ul>

         <h5 className='fw-bold '>Information Sharing</h5>
        <p>
          We may share your information with third parties in the following
          circumstances:
        </p>
        <ul>
          <li>
            With service providers and vendors who assist us in operating our
            website and providing our services.
          </li>
          <li>
            With law enforcement agencies or government authorities if required
            by law or to protect our rights.
          </li>
          <li>
            With your consent or as otherwise disclosed at the time of
            collection.
          </li>
        </ul>

         <h5 className='fw-bold '>Data Security</h5>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          so we cannot guarantee absolute security.
        </p>
      

        {/* Add other sections here following the same pattern */}

         <h5 className='fw-bold '>Your Choices</h5>
        <p>
          You can choose not to provide certain information to us, but it may
          limit your ability to use certain features of our website or services.
          You can also opt-out of receiving promotional emails from us by
          following the instructions provided in those emails.
        </p>

        <h5 className='fw-bold '>Changes to This Privacy Policy</h5>
        <p>
          We may update this Privacy Policy from time to time, and any changes
          will be posted on this page. We encourage you to review this Privacy
          Policy periodically for any updates.
        </p>

         <h5 className='fw-bold '>Contact Us</h5>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          practices, you can contact us at{" "}
          <a href="mailto:shaktishreesarv@gmail.com">
            shaktishreesarv@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};




export default Privacypolicy;
