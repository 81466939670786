import http from "../services/http-common";

class GetOrderService {
  getAll() {
    return http.get("getOrderInfosByCustomerId");
  }

  get(id) {
    return http.get(`getOrderInfosByCustomerId/${id}`);
  }

  create(data) {
    return http.post("getOrderInfosByCustomerId", data);
  }
  changeOrderStatus(data) {
    return http.post("changeOrderStatus", data);
  }

  update(id, data) {
    return http.put(`getOrderInfosByCustomerId/${id}`, data);
  }

  delete(id) {
    return http.delete(`getOrderInfosByCustomerId/${id}`);
  }

  deleteAll() {
    return http.delete(`getOrderInfosByCustomerId`);
  }

  findByTitle(title) {
    return http.get(`getOrderInfosByCustomerId?title=${title}`);
  }
}

export default new GetOrderService();
