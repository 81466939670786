const LanguageSelector = (state ="Hindi", action) => {
  switch (action.type) {
    case "English":
      return action.payload;

    case "Hindi":
      return action.payload;

    default:
      return state;
  }
};
export default LanguageSelector;






