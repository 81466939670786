import { Link } from "react-router-dom";
import parse from "html-react-parser";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeatureProduct from "./FeatureProduct";
import { CATDATA, MYDATA } from "../Constant";
import Headline from "./ Headline";
import flower from "../images/art/Flower.png";
const ProductGridCard = ({ item, navigate, showEdit, nama }) => {
  const scrollBarStyle = {
    width: "1px",
  };
  return (
    <div>
      {/* <div>
        <img
          src={flower}
          alt="..."
          className="position-absolute rounded-3 w-100 h-100"
          style={{ objectFit: "cover" }}
        />
      </div> */}
      <div class="mb-3 fw-semibold">
        {/* <table>
          <td>
            <h3>{item.title}</h3>
          </td>

          <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
            <div className="">
              {showEdit ? (
                <div>
                  <button
                    onClick={() =>
                      navigate(
                        "products" ,
                        localStorage.setItem(MYDATA, JSON.stringify(item))
                      )
                    }
                    type="button"
                    class="btn btn-primary me-md-2"
                  >
                    View all
                  </button>
                  <button
                    className="btn btn-primary "
                    onClick={() =>
                      navigate(
                        "addcat" + "/" ,
                        localStorage.setItem(CATDATA, JSON.stringify(item))
                      )
                    }
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    Edit
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </table> */}
        <Headline
          item={item}
          navigate={navigate}
          showEdit={showEdit}
          nama={nama}
        />
      </div>
      {/* <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 px-md-6 pt-1"> */}
      <div className="d-flex flex-row flex-nowrap overflow-auto no-scrollbar">
        {item.items.map((it) => (
          <div>
            <FeatureProduct key={it.itemId} product={it} />
          </div>
        ))}
      </div>
      <style>
        {`
          .d-flex.flex-row.flex-nowrap.overflow-auto::-webkit-scrollbar {
            width: ${scrollBarStyle.width};
          }
         
        `}
      </style>
    </div>
  );
};
export default ProductGridCard;
