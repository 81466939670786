const data = {
  info: {
    name: "Raghvendra Sashtri",
    short_description: "Hi, I'Raghvendra Sashtri.",
    image_url:
      "https://drive.google.com/uc?id=1JSF9MxINalavSrOBMsuQiWD9AVnIeT_g",
    description:
      "<h3>I'm a full stack Pujans experiencing more than 4+ years with <br><code>Vastu</code>, <code>Hawan</code>, <code>Grah Pravesh</code>, <code>Special Puja</code>, <code>Guru Pujan</code>, <code>Devi Maa</code> <br>and many more..</h3>",
    phone_no: "+917985082508",
    email: "shaktishreesarv@gmail.com",
  },

  social: {
    facebook: "https://www.facebook.com/ShreeSarvShakti",
    twitter: "https://twitter.com/shaktishreesarv",
    github: "https://github.com/shaktishreesarv",
    linkedin: "https://www.linkedin.com/in/shaktishreesarv",
  },

  portfolioCategories: ["Web Design", "Web Development", "App Development"],

  portfolios: [
    {
      id: 1,
      category: "Web Development",
      title: "DeshiBazaarBD - A Multi vendor Ecommerce market place",
      image:
        "https://akash.devsenv.com/api/public/images/portfolios/deshibazaarbd/desi-homepage.png",
    },
    {
      id: 2,
      category: "Web Development",
      title: "DMWords - A Big Dictionary Project",
      image:
        "https://akash.devsenv.com/api/public/images/portfolios/dmwords/dmwords-home.png",
    },
    {
      id: 3,
      category: "Web Design",
      title: "Pipe Line Admin Panel Design",
      image: "https://i.ibb.co/DfGqqtp/01-Home-Page-Dashboard.png",
    },
    {
      id: 4,
      category: "App Development",
      title: "iApp - Intelligent Business App",
      image: "https://i.ibb.co/WgMTCYH/playstore-demo.png",
    },
    {
      id: 5,
      category: "Website Development",
      title: "Akij City - A POS Solution",
      image:
        "https://akash.devsenv.com/api/public/images/portfolios/akij-city/akij-city-logo.png",
    },
    {
      id: 6,
      category: "Website Development",
      title: "PSTU - A University Website",
      image:
        "https://akash.devsenv.com/api/public/images/portfolios/pstu/pstu.png",
    },
  ],
};

export default data;
