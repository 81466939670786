import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCatService from "./AddCatService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import swal from "sweetalert";

import {
  SESSION_ID,
  BASE_URL,
  DELETE_CATEGORY,
  AddCategory,
  ACCESS_TOKEN,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";

export default function (props) {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };

  const catData = localStorage.getItem("catData");
  const catItem = JSON.parse(catData);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({
    catId: catItem.catId,
    orderNo: catItem.orderNo,
    title: catItem.title,
    titleID: catItem.titleID,
    subTitle: catItem.subTitle,
    subTitleID: catItem.subTitle,
    viewType: catItem.viewType,
    iconURL: catItem.iconURL,
    price: catItem.price,
    priceInDollar: catItem.priceInDollar,

    // new
    //catType: catItem.catType,
    gridViewOn: catItem.gridViewOn,
    layoutWidth: catItem.layoutWidth,
    layoutHeight: catItem.layoutHeight,
    headerColor: catItem.headerColor,
    viewMoreTitle: catItem.viewMoreTitle,
    viewMoreTitleID: catItem.viewMoreTitleID,
    callingTag: catItem.callingTag,
    callingLink: catItem.callingLink,
    callingURL: catItem.callingURL,
    discountPrice: catItem.discountPrice,
    description: catItem.description,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCategory({ ...category, [e.target.name]: value });
  };

  const saveCategory = (e) => {
    e.preventDefault();
    // category.subTitle === "" ||
    //   category.subTitleID === "" ||
    //   category.viewType === "" ||
    //   category.iconURL === ""
    if (
      category.title === "" ||
      category.title === undefined ||
      category.titleID === ""
    ) {
      swal(" Please enter all fields ");
    } else {
      const catData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            catId: category.catId,
            orderNo: category.orderNo,
            title: category.title,
            titleID: category.titleID,
            subTitle: category.subTitle,
            subTitleID: category.subTitleID,
            viewType: category.viewType,
            iconURL: category.iconURL,
            price: category.price,
            priceInDollar: category.priceInDollar,
            //new
           // catType: category.catType,
            gridViewOn: category.gridViewOn,
            layoutWidth: category.layoutWidth,
            layoutHeight: category.layoutHeight,
            headerColor: category.headerColor,
            viewMoreTitle: category.viewMoreTitle,
            viewMoreTitleID: category.viewMoreTitleID,
            callingTag: category.callingTag,
            callingLink: category.priceInDollar,
            callingURL: category.callingURL,
            discountPrice: category.discountPrice,
            description: category.description,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      // AddCatService.saveCategory(categoryData)
      axios
        .post(BASE_URL + AddCategory, catData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        })

        .then((response) => {
          swal(response.data.respData.message);

          navigate("/");
          if(DEBUG_LOG_ENABLED){
          console.log(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/login");
          } else {
            swal("data : " + err);
            if(DEBUG_LOG_ENABLED){
            console.log(err);
            }
          }
        });
    }
  };

  const removeCatData =  (e) => {
    try {
      const removeCat = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            catId: category.catId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await AddCatService.deleteCategory(removeCat);
      let response =  axios.post(BASE_URL + DELETE_CATEGORY, removeCat, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        },
      });

      swal(response?.data?.respData?.message);
      navigate("/");
    } catch (err) {
      if (err?.response?.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if(DEBUG_LOG_ENABLED){
        console.log(err);
        }
      }
    }
  };

 
  return (
    <div className="align-items-center   py-5 px-xl-5 mx-auto mt-5 ">
      <div className="">
        {/* <div className="col-lg-12"> */}
        <div className="">
          <ScrollToTopOnMount />
          <form className=" ">
            <div className=" shadow-lg p-3 mb-5 bg-body rounded ">
              <h4 className="">Add category</h4>

              <div class="row">
                <div class="col">
                  <div className="form-group mt-3">
                    <label>Cat Id</label>
                    <input
                      type="number"
                      name="catId"
                      value={category.catId}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>orderNo</label>
                    <input
                      type="number"
                      name="orderNo"
                      value={category.orderNo}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-1">
                    <label>title</label>
                    <input
                      type="text"
                      name="title"
                      value={category.title}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>subTitle</label>
                    <input
                      type="text"
                      name="subTitle"
                      value={category.subTitle}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>subTitleID</label>
                    <input
                      type="text"
                      name="subTitleID"
                      value={category.subTitleID}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>gridViewOn</label>
                    <input
                      // type="numbar"
                      name="gridViewOn"
                      value={category.gridViewOn}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>layoutWidth</label>
                    <input
                      //  type="numbar"
                      name="layoutWidth"
                      value={category.layoutWidth}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>layoutHeight</label>
                    <input
                      // type="numbar"
                      name="layoutHeight"
                      value={category.layoutHeight}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>headerColor</label>
                    <input
                      // type="numbar"
                      name="headerColor"
                      value={category.headerColor}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>viewMoreTitle</label>
                    <input
                      // type="numbar"
                      name="viewMoreTitle"
                      value={category.viewMoreTitle}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  {/* <div className="form-group mt-3">
                    <label>catType</label>
                    <input
                      // type="numbar"
                      name="catType"
                      value={category.catType}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div> */}
                 
                </div>
                <div class="col">
                  <div className="form-group mt-3">
                    <label>titleID</label>
                    <input
                      type="text"
                      name="titleID"
                      value={category.titleID}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>ViewType</label>
                    <input
                      type="text"
                      name="viewType"
                      value={category.viewType}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>iconURL</label>
                    <input
                      type="text"
                      name="iconURL"
                      value={category.iconURL}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Price In INR (₹)</label>
                    <input
                      type="numbar"
                      name="price"
                      value={category.price}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Price In USD ($)</label>
                    <input
                      type="numbar"
                      name="priceInDollar"
                      value={category.priceInDollar}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>viewMoreTitleID</label>
                    <input
                      //  type="numbar"
                      name="viewMoreTitleID"
                      value={category.viewMoreTitleID}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>callingTag</label>
                    <input
                      //  type="numbar"
                      name="callingTag"
                      value={category.callingTag}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>callingLink</label>
                    <input
                      // type="numbar"
                      name="callingLink"
                      value={category.callingLink}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>callingURL</label>
                    <input
                      // type="numbar"
                      name="callingURL"
                      value={category.callingURL}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>discountPrice</label>
                    <input
                      // type="numbar"
                      name="discountPrice"
                      value={category.discountPrice}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>description</label>
                    <input
                      //  type="numbar"
                      name="description"
                      value={category.description}
                      onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                    />
                  </div>
                </div>
              </div>

              <div className="d-grid gap-2 mt-3">
                <button
                  name="save"
                  onClick={saveCategory}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>

              <div className="d-grid gap-2 mt-3">
                <button
                  name="delete"
                  type="submit"
                  className="btn btn-primary   mt-3"
                  onClick={removeCatData}
                >
                  <FontAwesomeIcon icon="fa-solid fa-trash " /> Delete
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
