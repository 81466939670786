import http from "../services/http-common";

class AddCatService {
  saveCategory(data) {
    return http.post("addCategory", data);
  }

  deleteCategory(data) {
    return http.post("deleteCategory", data);
  }

  updateCategory(id, data) {
    return http.put(`addCategory/${id}`, data);
  }
}

export default new AddCatService();
