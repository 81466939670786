// import React from 'react'

// export default function Googlereview() {
//   return (
//     <div>Googlereview</div>
//   )
// }








// import React, { useEffect, useState } from "react";
// import axios from "axios";

// // const Googlereview = ({ apiKey, placeId }) => {
//     const Googlereview = () => {
//       const [reviews, setReviews] = useState([]);
//       const placeId = "ChIJy58hTODZhDkRO8_l1KpOIIo";
//       const apiKey = "AIzaSyBiUta5yIyr0rZ0gYvwNrgnbCY4a2c7dX0";
//     //  const apiKey = "AIzaSyCYZVRaBZnrEDcLofFjAnV7ixgCbUtK1eM";

//       useEffect(() => {
//         const fetchReviews = async () => {
//           try {
//             const response = await axios
//               .get 
//              // "https://mybusiness.googleapis.com/v4/accounts/4882363808068829605/locations/ChIJy58hTODZhDkRO8_l1KpOIIo"();
//               `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`

//               //'https://maps.googleapis.com/maps/api/js?key=AIzaSyC76hIyVhP_MTBZ19mGF6B0wLIzNGcpVKs&libraries=ChIJy58hTODZhDkRO8_l1KpOIIo'

//             if (response.data.result) {
//               setReviews(response.data.result.reviews);
//             }
//           } catch (error) {
//             alert("Error fetching Google Reviews:", error);
//           }
//         };

//         fetchReviews();
//       }, [apiKey, placeId]);

//       return (
//         <div>
//           <h2>Google Reviews</h2>

//           <ul>
//             {reviews.map((review) => (
//               <li key={review.time}>
//                 <p>{review.text}</p>
//                 <p>Rating: {review.rating}</p>
//                 <p>By: {review.author_name}</p>
//               </li>
//             ))}
//           </ul>
//         </div>

//       );
//     };

// export default Googlereview;




import React from "react";
import Headline from "./ Headline";
import CustomerReview from "./CustomerReview";
function GoogleMap({ item, navigate, showEdit, nama }) {
  return (
    <>
      <div class="mb-3 fw-semibold">
        <Headline
          item={item}
          navigate={navigate}
          showEdit={showEdit}
          nama={nama}
        />
      </div>
      <CustomerReview />
      <div className="px-3">
        <iframe
          title="Google Map"
          //src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14446.836384378981!2d81.4373564!3d25.145524!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3984d9e04c219fcb%3A0x8a204eaad4e5cf3b!2sShree%20Sarv%20Shakti!5e0!3m2!1sen!2sin!4v1696zz"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.591527559144!2d81.87742958247578!3d25.418480164884315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39854abc2623f04b%3A0x8b5d693bbd19a937!2sSri%20Sacha%20Baba%20Ashram!5e0!3m2!1sen!2sin!4v1705470877587!5m2!1sen!2sin"
          width="100%"
          height="300"
          frameBorder="0"
          allowFullScreen="true"
        ></iframe>
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.591527559144!2d81.87742958247578!3d25.418480164884315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39854abc2623f04b%3A0x8b5d693bbd19a937!2sSri%20Sacha%20Baba%20Ashram!5e0!3m2!1sen!2sin!4v1705470877587!5m2!1sen!2sin"
          width="600"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}

      </div>
    </>
  );
}

export default GoogleMap;
