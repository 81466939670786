import http from "./http-common";

class TransKey {
  getAll() {
    return http.get("GetTransKey");
  }

  get(id) {
    return http.get(`GetTransKey/${id}`);
  }

  create(data) {
    return http.post("GetTransKey", data);
  }

  update(id, data) {
    return http.put(`GetTransKey/${id}`, data);
  }

  delete(id) {
    return http.delete(`GetTransKey/${id}`);
  }

  deleteAll() {
    return http.delete(`GetTransKey`);
  }

  findByTitle(title) {
    return http.get(`GetTransKey?title=${title}`);
  }
}

export default new TransKey();