import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import CheckoutStepper from "../components/CheckoutStepper";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { increment } from "../actions";
import {
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  SetOrders,
  ACCESS_TOKEN,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
import moment from "moment";
import { useLoaderData, useNavigate } from "react-router-dom";
import swal from "sweetalert";

export default function OrderCancel() {
 const [cartList, setcartList] = useState([]);
 const dispatch = useDispatch();
 const navigate = useNavigate();

  const [newlocation, setnewlocation] = useState(" ");

 const addressData = localStorage.getItem("addressData");
 const addressList = JSON.parse(addressData);

 const setOrder = async () => {
   const amount = localStorage.getItem("price");
   const currency = localStorage.getItem("currency");
   const orderID = localStorage.getItem("UpiOderId");

   try {
     const setOrderItem = JSON.stringify({
       pubInfo: {
         sessionId: localStorage.getItem(SESSION_ID),
       },
       request: {
         busiParams: {
           amount: amount,
           customerId: localStorage.getItem(CUSTOMER_ID),
           paymentId: orderID,
           addressId: addressList.id,
           date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
           paymentType: 1,
           currency: currency,
           customerPhone: addressList.mobileNo,
         },
         isEncrypt: false,
         transactionId: "897987987989",
       },
     });
     // const response = await OrderReviewService.create(setOrderItem);
     let response = await axios.post(BASE_URL + SetOrders, setOrderItem, {
       headers: {
         "Access-Control-Allow-Origin": "*",
         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
         "Access-Control-Allow-Headers": "Content-Type",
         "Content-Type": "application/json",
         Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
       },
     });

     localStorage.setItem("price", "");
     localStorage.setItem("currency", "");
     localStorage.setItem("UpiOderId", "");
   } catch (err) {
     swal("data : " + err);
     if (DEBUG_LOG_ENABLED) {
       console.log(err);
     }
   }
 };


   const completepayPalPayment = async (token) => {
     try {
       const completepay = JSON.stringify({
         pubInfo: {
           sessionId: localStorage.getItem(SESSION_ID),
         },
         request: {
           busiParams: {
             token: token,
           },
           isEncrypt: false,
           transactionId: "897987987989",
         },
       });
       // const response = await OrderReviewService.create(setOrderItem);
       let response = await axios.post(
         BASE_URL + "completePaypalPayment",
         completepay,
         {
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
             "Access-Control-Allow-Headers": "Content-Type",
             "Content-Type": "application/json",
             Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
           },
         }
       );
     } catch (err) {
       swal("data : " + err);
       if (DEBUG_LOG_ENABLED) {
         console.log(err);
       }
     }
   };
    useEffect(() => {
     const location = window.location.href;

     var urlspit = location.split("token=").pop();
     var urlBrek = urlspit.split("&");
     setnewlocation(urlBrek[0]);
     // swal(urlBrek[0]);

     completepayPalPayment(urlBrek[0]);
     
      dispatch(increment(0));
      const currency = localStorage.getItem("currency");
      if (currency === "USD") {
        localStorage.setItem("price", "");
        localStorage.setItem("currency", "");
        localStorage.setItem("cartListData", JSON.stringify(cartList));
        localStorage.setItem("clientId", "");
        localStorage.setItem("razorpayKeyId", "");
      } else {
        const orderId = localStorage.getItem("UpiOderId");
        if (orderId !== "") {
          setOrder();
        }
      }
    }, []);






  return (
    <div className="container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
      <div className="col-md-12">
        <CheckoutStepper step={4} />
      </div>
      <div className="row h-100">
        <div className="col-md-12 h-100">
          <div className="card border-0 shadow-sm h-100">
            <div className="card-body mt-5">
              <div className="d-flex justify-content-center mb-3">
                {/* <FontAwesomeIcon
                  icon={("fas", "check-circle")}
                  size="5x"
                  className="text-success"
                /> */}
                <FontAwesomeIcon
                  icon="fa-solid fa-ban"
                  beat
                  size="5x"
                  className="text-danger"
                />
              </div>
              <h3 className="text-center text-danger ">Payment Unsuccessful</h3>
              <p className="text-center text-danger ">
                We regret to inform you that we were unable to process your
                payment.
              </p>
              <h6 className="text-center h-5">
                Please check your payment information and try again. If the
                issue persists, please contact our support team for assistance.
                <br></br> Thank you for your understanding. 
                {/* {localStorage.getItem("UpiOderId")}; */}
                {/* {localStorage.getItem("orderID")} */}
                <span> </span>
                <Link to={"/"}>
                  <a> continue</a>
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
