import React from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import swal from "sweetalert";

import {
  SESSION_ID,
  CUSTOMER_ID,
  BASE_URL,
  ORDER_HISTORY_DETAIL,
  getCustomerAddressByUserId,GET_OREDER_HISTORY,
  ACCESS_TOKEN,
  ADDRESSLIST,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { increment } from "../actions";
import GetOrderService from "./GetOrderService";
import RemoveOrderHistoryService from "./RemoveOrderHistoryService";
import AccountMenu from "../profile/account-menu";
import OrderHistoryItemDetail from "./OrderHistoryItemDetail";
function OrderHistoryDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderItemList, setOrderItemList] = useState([]);
  const [addressList, setAddressList] = useState([]);

  const getAddressLists = async () => {
    try{
    const customerData = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          customerId: localStorage.getItem(CUSTOMER_ID),
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });

   let response = await axios.post(
        BASE_URL + getCustomerAddressByUserId,
        customerData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      setAddressList(filterByAddressId(response?.data?.respData?.respMsg));
          localStorage.setItem(
            ADDRESSLIST,
            JSON.stringify(response?.data?.respData?.respMsg));
    }catch(err) {
        if (err.response.status == 401) {
          navigate("/login");
        } else {
          swal("data : " + err);
          if (DEBUG_LOG_ENABLED) {
            console.log(err);
          }
        }
      };
  };

  const filterByAddressId = (addressItemList, addressId) => {
    const addressItem = addressItemList.filter((item) => item.id === addressId);
    return addressItemList;
  };
  const getOrdertLists = async () => {
    try {
      const orderListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await GetOrderService.create(orderListData);
      let response = await axios.post(BASE_URL + GET_OREDER_HISTORY, orderListData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });





      // swal("data : "+response.data.respData.respMsg.sessionId);
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      if(DEBUG_LOG_ENABLED){
      console.log("orderItemList : " + orderItemList);
      }
      //swal("data : "+catItemList.length);
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if(DEBUG_LOG_ENABLED){
        console.log(err);
        }
      }
    }
  };
  useEffect(() => {
    getOrdertLists();
    const addressList = localStorage.getItem("addressList");
    const addressItemList = JSON.parse(addressList);
    if (addressItemList?.lenght === 0) {
      getAddressLists();
    } else {
      setAddressList(addressItemList);
    }
  }, []);

 

  return (
    <div className="container mt-5 py-5 px-xl-5">
      <div className="container py-4">
        <div className="row g-3">
          <div className="col-lg-3">
            <AccountMenu current="order-history" />
          </div>
          <div className="col-lg-9">
            {orderItemList?.length === 0 ? (
              <div className="container py-4">Empty order history</div>
            ) : (
              <div>
                {orderItemList?.map((it) => (
                  <div>
                    <OrderHistoryItemDetail
                      id={it.orderNum}
                      product={it}
                      addressData={filterByAddressId(addressList, it.addressId)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default OrderHistoryDetail;
