import { Link } from "react-router-dom";
import parse from "html-react-parser";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";

import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  MYPRODUCT,
  DEBUG_LOG_ENABLED,
  BASE_URL_IMG,
} from "../Constant";

import { useSelector, useDispatch } from "react-redux";
import { increment } from "../actions";
import axios from "axios";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";
function changeRating(newRating) {}
function ProductItemCart({ product }) {
  const dispatch = useDispatch();
  //const {state, dispatch} = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const isLogged = useSelector((state) => state.isLogged);

  const addToMycart = () => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (DEBUG_LOG_ENABLED) {
      console.log("CartService : id:" + id + "  token:" + token);
    }
    if (isLogged && id != null && id != "" && token != "") {
      addToCart();
    } else {
      navigate("/login");
    }
  };
  const addToCart = async () => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: product.itemId,
            price: product.price,
            panditCount: 0,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : " + itemData);
      }

      //const response = await CartService.addToCart(itemData);
      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      dispatch(increment(response.data.respData.respMsg.cartCount));
      //dispatch(increment(response.data.respData.respMsg.cartCount));
      swal(response.data.respData.message);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  return (
    <div class="card-group pt-5">
      <div class="card ">
        <div class="row ">
          <div class="col-md-4 ">
            <Link
              to={"/productdetail/" + product.itemId}
              href="!#"
              onClick={() =>
                navigate(
                  "/productdetail/" + product.itemId,
                  localStorage.setItem("myProduct", JSON.stringify(product))
                )
              }
            >
              <img
                src={BASE_URL_IMG + product.iconURL}
                class="img-fluid rounded-start m-3"
                alt="..."
              />
            </Link>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div className="d-flex">
                <div className="d-inline h5 mb-0 fw-semibold me-3">
                  <h5 class="card-title">{product.title}</h5>
                </div>
                <div className="ms-auto">
                  <h6 class="card-title">
                    {"$" + product.price} <br />
                    <p>
                      <small class="text-muted"> Fee </small>
                    </p>
                  </h6>
                </div>
              </div>

              {/* <p class="card-text">
                <small class="text-muted text-decoration-none  ">{parse(`${product.subTitle}`)}</small>
                
              </p> */}

              <div class="row">
                <div class="col-9 text-truncate">
                  {parse(`${product.subTitle}`)}
                </div>
              </div>

              <div className="d-flex">
                <div className="ms-auto">
                  <button class="btn btn-primary" type="button">
                    Book
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductItemCart;
