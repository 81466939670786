
import React from 'react'

function RefundPolicy() {
  return (
    <div class="container">
      <h1 class="mt-5 mb-4">Refund Policy</h1>
      <h6>Last Updated:15 Feb 2024</h6>
      <p class="mb-4">
        Thank you for choosing Shree Sarvashakti for your online puja services.
        We strive to provide high-quality services to our customers. Please read
        our refund policy carefully.
      </p>

      <h3>Interpretation and Definitions</h3>
      <p>
        Words capitalized herein have meanings as defined under the following
        conditions. The following definitions apply regardless of singular or
        plural usage.
      </p>

      <h4>Definitions</h4>
      <ul>
        <li>
          <strong>Company:</strong> Shree Sarvashakti, a service provider
          website offering online puja services.
        </li>
        <li>
          <strong>Service:</strong> The online puja services provided by the
          Company.
        </li>
        <li>
          <strong>Customer:</strong> Any individual or entity that purchases or
          intends to purchase services from the Company.
        </li>
        <li>
          <strong>Website:</strong> The Shree Sarvashakti website, accessible
          from [https://shreesarvshakti.com](https://shreesarvshakti.com).
        </li>
        <li>
          <strong>You:</strong> The individual accessing or using the Service,
          or any legal entity on whose behalf such individual accesses or uses
          the Service.
        </li>
      </ul>

      <h3>Refund Policy</h3>
      <p>
        We understand that circumstances may arise where you might request a
        refund for the puja services purchased from Shree Sarvashakti. Our
        refund policy is as follows:
      </p>

      <ol>
        <li>
          <strong>Cancellation and Refunds:</strong>
          <ul>
            <li>
              You have the right to cancel your puja service order within 24
              hours of purchase.
            </li>
            <li>
              To request a cancellation and refund, please contact us via{" "}
              <a href="https://shreesarvshakti.com/#/contact">
                https://shreesarvshakti.com/#/contact
              </a>{" "}
              within the specified time frame.
            </li>
            <li>
              Refunds for eligible cancellations will be processed using the
              original method of payment within 2 weeks of receiving your
              cancellation request.
            </li>
          </ul>
        </li>
        <li>
          <strong>Non-Refundable Services:</strong>
          <p>
            Some puja services may be non-refundable due to the nature of the
            service provided or specific arrangements made by the Company. In
            such cases, it will be clearly indicated at the time of purchase.
          </p>
        </li>
        <li>
          <strong>Refund Eligibility:</strong>
          <p>
            Refunds will only be issued for cancellations made within the
            specified time frame and in accordance with our cancellation policy.
            Refunds will not be provided for services that have already been
            performed or completed, unless there are extenuating circumstances
            deemed acceptable by the Company.
          </p>
        </li>
      </ol>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about our Refund Policy or need assistance
        with a refund request, please contact us via{" "}
        <a href="https://shreesarvshakti.com/#/contact">
          https://shreesarvshakti.com/#/contact
        </a>
        .
      </p>

      <h4>Disclaimer</h4>
      <p>
        Please note that this Refund Policy applies only to services purchased
        directly from Shree Sarvashakti. If you have purchased services through
        a third-party platform or provider, their refund policies may apply.
      </p>
    </div>
  );
}

export default RefundPolicy