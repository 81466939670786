

import React, { useState, useEffect } from "react";
import GetCategoryItemService from "../services/GetCategoryItemService";
import {
  MYPRODUCT,
  SESSION_ID,
  DEBUG_LOG_ENABLED,
  USER_ROLE,
  ROLE,
  BASE_URL_IMG,
  CATITEM,
} from "../Constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import gods from "../data/god_pic.json";
import defaultBannerData from "../data/default_banner_data.json";
import { havan, swastik, torans } from "../utils/icons";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import moment from "moment";


const mainImageStyles = {
  objectFit: 'fill',
  width: '100%',
  maxWidth: '442px',
  minWidth: "100%",
  height: '277px',
  cursor: 'pointer',
  ...window.innerWidth >= 768 && window.innerWidth < 992 && { minWidth: '100%', height: '400px' },
  ...window.innerWidth >= 992 && { maxWidth: '442px', height: '446px' },
};

const overlayImageStyles = {
  marginTop: '-43px',

  width: '85px',
  height: '125px',
  ...window.innerWidth >= 992 && { marginTop: '-90px', width: '190px', height: '278px' },
};




function Banner(item) {
  const [catItemList, setCatItemList] = useState([]);

  const scrollRef = useRef(null); 
  const indicatorRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMM"));
  const dropdownRef = useRef(null);  

  const [isHovering, setIsHovering] = useState(false);

const handleMouseEnter = () => {
  setIsHovering(true); 
};

const handleMouseLeave = () => {
  setIsHovering(false);
};
  const monthOptions = Array.from({ length: 12 }, (_, i) => moment().month(i).format("MMMM"));

  const handleMonthSelect = (month) => {
    setSelectedMonth(moment(month, "MMMM").format("MMM"));
    setIsDropdownOpen(false);
    // You can add further logic for handling the date selection here if needed.
  };

  useEffect(() => {
    setCatItemList(item?.product?.items);
  }, [item]);
   // Close dropdown on outside click
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIndicatorVisibility = (index) => {
    const indicatorContainer = indicatorRef.current;
    const indicator = indicatorContainer?.children[index]; // Target the active dot

    if (indicator && indicatorContainer) {
        const indicatorRect = indicator.getBoundingClientRect();
        const containerRect = indicatorContainer.getBoundingClientRect();

        if (indicatorRect.left < containerRect.left || indicatorRect.right > containerRect.right) {
            indicator.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }
    }
};
  useEffect(() => {
    if (!scrollRef.current || catItemList.length === 0) return;
  
    let interval;
    if (!isHovering) {
      interval = setInterval(() => {
        const scrollContainer = scrollRef.current;
        const itemWidth = scrollContainer.firstChild?.getBoundingClientRect().width || 0;
        if (currentIndex < catItemList.length - 1) {
          const newIndex = currentIndex + 1;
          scrollContainer.scrollTo({
            left: newIndex * itemWidth,
            behavior: "smooth",
          });
          setCurrentIndex(newIndex);
          handleIndicatorVisibility(newIndex);
        } else {
          scrollContainer.scrollTo({
            left: 0,
            behavior: "smooth",
          });
          setCurrentIndex(0);
          handleIndicatorVisibility(0);
        }
      }, 3000); // Adjust interval timing as needed
    }
  
    return () => clearInterval(interval);
  }, [currentIndex, catItemList, isHovering]);
  


  
  const handleDotClick = (index) => {
    const scrollContainer = scrollRef.current;
    const card = scrollContainer?.firstChild; // Get the first card for width calculation
    const itemWidth = card ? card.getBoundingClientRect().width : 0; // True width including padding/margin
  
    if (scrollContainer) {
      scrollContainer.scrollTo({
        left: index * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(index); // Update the active index
      handleIndicatorVisibility(index);
    }
    
  };
  
  const handleNextClick = () => {
    const scrollContainer = scrollRef.current;
    const card = scrollContainer?.firstChild;
    const itemWidth = card ? card.getBoundingClientRect().width : 0;
  
    if (currentIndex < catItemList.length - 1) {
      const newIndex = currentIndex + 1;
      scrollContainer.scrollTo({
        left: newIndex * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(newIndex); // Update the active index
      handleIndicatorVisibility(newIndex);
    } else {
      scrollContainer.scrollTo({
        left: 0,
        behavior: "smooth",
      });
      setCurrentIndex(0);
      handleIndicatorVisibility(0);
    }
  };
  
  const handlePrevClick = () => {
    const scrollContainer = scrollRef.current;
    const card = scrollContainer?.firstChild;
    const itemWidth = card ? card.getBoundingClientRect().width : 0;
  
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      scrollContainer.scrollTo({
        left: newIndex * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(newIndex);      // Update the active index
      handleIndicatorVisibility(newIndex);
    } else {
      const newIndex = catItemList.length - 1;
      scrollContainer.scrollTo({
        left: newIndex * itemWidth,
        behavior: "smooth",
      });
      setCurrentIndex(newIndex);
      handleIndicatorVisibility(newIndex);
    }
  };
    


  return (
    <div
      style={{
        backgroundImage: `url(${swastik})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="mt-5 pt-5 "
    >
     
     <div
        ref={scrollRef}
        className="d-flex no-scrollbar pe-3"
        style={{
          width: "100%", 
          gap: window.innerWidth > 768?"10px":"2px", 
          overflowX: "auto", 
          whiteSpace: "nowrap",
          scrollSnapType: "x mandatory",
          minHeight:window.innerWidth > 768?'400px':"500px"
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {catItemList?.sort((a, b) => a.orderNo - b.orderNo)
        ?.map((it, index) => (
          <div
            key={index}
            className="ps-md-3 ps-3" 
            style={{
              // minWidth:window.innerWidth > 768? "90%":"90%", 
              minWidth:"90%",
              scrollSnapAlign: "start", 
              marginRight:window.innerWidth > 768?"16px":"0",
              transition: "transform 0.5s ease",
              borderRadius: "8px", 
            }}
          >
            <BannerCrouselImage
              item={it}
              idx={index}
            />
          </div>
        ))}
      </div>

      {/* Indicators */}
      <div className="mx-3">
      <div className="d-flex justify-content-center mt-3 align-items-center  mx-auto " style={{ gap:window?.innerWidth > 768?"16px":"1px",}}>
                <div className="position-relative " ref={dropdownRef}>
                    <p
                      // onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      className="px-2 rounded-2 text-primary d-flex"
                      style={{ cursor: "pointer", margin: 0,  alignItems: "center" }}
                    
                    >               
                        <span  className="text-black fw-semibold pe-1" style={{ fontFamily: "sans-serif" }}>
                        {catItemList?.[currentIndex]?.headerColor
                            ? moment(catItemList[currentIndex].headerColor,"YYYY-MM-DD").format("MMM")
                            : "--"}</span>
                      {/* <FontAwesomeIcon icon="fa-solid fa-angle-down" /> */}
                    </p>

                    {/* Dropdown Menu */}
                    {/* {isDropdownOpen && (
                      <div
                        className="position-absolute bg-white border rounded shadow"
                        style={{
                          top: "100%",
                          left: -2,
                          zIndex: 10,
                          maxHeight: "200px", 
                          overflowY: "scroll",
                          transition: "opacity 0.3s ease, transform 0.3s ease",
                          transform: "translateY(10px)",
                          opacity: isDropdownOpen ? 1 : 0,
                          minWidth: "100px"
                        }}
                      >
                        {monthOptions.map((month, index) => (
                          <p
                            key={index}
                            onClick={() => handleMonthSelect(month)}
                            className="m-0 p-2 text-start"
                            style={{
                              cursor: "pointer",
                              fontFamily: "sans-serif",
                              transition: "background-color 0.3s ease",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = "#F5F5F5"}
                            onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                          >
                            {month}
                          </p>
                        ))}
                      </div>
                    )} */}
                </div>
     
                <p onClick={handlePrevClick} 
                  className="p-2 rounded-2 text-primary"
                  style={{ cursor: 'pointer', margin: 0 }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "rgba(247, 95, 1, 0.14)")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor ="transparent")}>
                  <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                </p>
                <div 
                  ref={indicatorRef}
                className="d-flex  no-scrollbar"
                style={{overflow:"scroll", scrollBehavior: "smooth", maxWidth: "70%", gap: "8px" }}>
                {catItemList?.sort((a, b) => a.orderNo - b.orderNo)?.map((item, idx) => (
                  <p
                  onClick={() => handleDotClick(idx)}
                  key={idx}
                  className={`d-flex text-center justify-content-center align-items-center fw-semibold`}
                  style={{
                    minWidth:40,
                    height:41,
                    fontSize:"16px",
                    borderRadius:"6px",
                    fontFamily:"sans-serif",
                    color:idx === currentIndex ? "#FF6100":"#8C8C8C",
                    backgroundColor: idx === currentIndex ? 'rgba(247, 95, 1, 0.14)' : '',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                    margin: 0,
                  
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "rgba(247, 95, 1, 0.14)")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = idx === currentIndex ? "rgba(247, 95, 1, 0.14)" : "transparent")}
                >
                  {/* {idx+1}  */}
                  {item?.headerColor?moment(item?.headerColor,"YYYY-MM-DD").format("D"):".."}
                </p>
                ))}
                </div>
                <p onClick={handleNextClick} 
                className="p-2 rounded-2 text-primary "
                  style={{ cursor: 'pointer', margin: 0 }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "rgba(247, 95, 1, 0.14)")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor ="transparent")}>
                  <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                </p>
      </div>
      </div>
 {/* Choose By God Section */}
 <div className="text-center" style={{ marginTop: window?.innerWidth > 768 ? 100 : 40, }}>
        <h1 className="h3 fw-medium" style={{ fontFamily: 'sans-serif' }}>Choose Puja By God</h1>
        <div className="d-flex overflow-auto py-4 px-3 gap-4 justify-content-between no-scrollbar">
          {gods.godpic.map((item, index) => (
            <div key={index} className="flex-shrink-0">
              <ChooseByGod item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Banner;

function BannerCrouselImage({item,idx}) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);

  const [itemWidth, setItemWidth] = useState(0);
  const itemRef = useRef(null);

  useEffect(() => {
    // Set the item width dynamically based on the container width
    const updateItemWidth = () => {
      if (itemRef.current) {
        setItemWidth(itemRef.current.clientWidth);
      }
    };

    // Initial width calculation
    updateItemWidth();

    // Add event listener to resize the window
    window.addEventListener("resize", updateItemWidth);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", updateItemWidth);
    };
  }, []);
  // console.log("items", items);
  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);


// console.log(item?.callingLink,"dd")
  return (
    <>
    {showEdit && (
      <div className="d-sm-flex mb-1" >

        <Link
          className="btn btn-outline-primary  mt-1"
          style={{ width: 'auto', height: "36px" }}
          to={"/addcatitem"}
          // replace
          onClick={() =>
            localStorage.setItem(CATITEM, JSON.stringify(item))
          }

        >
          <FontAwesomeIcon icon="fa-solid  fa-pen-to-square" />
          Edit
        </Link>
        </div>
        )}
    <div
    className="  col-md-12"
    style={{borderRadius:window.innerWidth > 768?"16px":"8px", width: "100%",cursor:'pointer',backgroundColor:item?.bgcolor?`${item?.bgcolor}`:"rgba(75, 36, 12, 1)", }}
    >
        <div className="d-md-flex  col-md-12 " onClick={() => {localStorage.setItem(MYPRODUCT, JSON.stringify(item))
                                 navigate("productdetail/" + item?.itemId,)
                                  }}>
       
            <div className="col-md-5" style={{borderBottomLeftRadius:"16px", borderTopLeftRadius:"16px",}}
            >
              <img
                src={idx ==4 ?"/god/NewHanuman.png":`${BASE_URL_IMG + item?.iconURL}`}
                // src="/god/NewHanuman.png"
                alt="..."
                style={{...mainImageStyles,    
                  ...window.innerWidth > 768?{borderTopLeftRadius: "16px", borderBottomLeftRadius: "16px"}:
                  {borderTopLeftRadius: "8px", borderTopRightRadius: "8px"},
                  // objectFit:'cover'
                }} 
                className="img-fluid  position-relative"
              />
            </div>
            <div
              className="col-md-7  position-relative rounded-end  text-md-start  " 
              style={{height:window.innerWidth>768?"": "301px"}}
              
            >
              <img
                src={havan}
                alt="..."
                className="position-absolute "
                style={{
                  ...window.innerWidth > 768?{ borderTopRightRadius:"16px", borderBottomRightRadius:"16px"}:
                  {borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}, 
                   objectFit: "cover", width: "100%",height:window.innerWidth>768?'100%':"301px" }}
              />
               <div
                      className="position-absolute top-0 start-0 px-lg-5 px-md-3 px-2 text-white "
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                                          }}
                       >
                    <h1
                      className="fw-semibold text-wrap mt-3"
                      style={{
                        fontSize:window.innerWidth>768?"40px":"22px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1, // Truncates text to 1 line if needed
                        whiteSpace: "normal",
                      }}
                    >
                      {item?.title}
                    </h1>

                    <p
                      className=" text-wrap"
                      style={{
                        fontSize:window.innerWidth>768?"24px":"16px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgba(255, 255, 255, 0.9)",
                        WebkitLineClamp:  4 , // Adjusts lines based on screen size
                        whiteSpace: "normal",
                        marginTop: window.innerWidth > 768?"8px":"0px",
                      }}
                    >
                      {/* {item?.subTitleID.replace(/(<([^>]+)>)/gi, "")} */}
                      {(() => {
                            try {
                              const parsedCallingLink = item?.callingLink ? JSON.parse(item.callingLink) : null;
                              if (Array.isArray(parsedCallingLink)) {
                                return (
                                  <>
                                    <span>Benifits</span>
                                      {parsedCallingLink.map((linkItem, index) => (
                                        <li key={index}>{linkItem?.benefit}</li>
                                      ))}
                                  </>
                                );
                              }
                            } catch (error) {
                              console.error("Error parsing callingLink:", error);
                            }
                          
                            return item?.subTitleID?.replace(/(<([^>]+)>)/gi, "");
                          })()
                        }
                    </p>
                    <div className="d-flex justify-content-center  px-auto mx-auto mt-auto mb-0   text-center gap-md-4 gap-2"
                      style={{
                        
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflowY: "hidden",
                        overflowX:"hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        width:"100%"
                      }}>
                          {(item?.panditPrice  ||item?.panditPriceInDollar )&& 
                          <><p className="fw-semibold" 
                          style={{fontSize:window.innerWidth>768?"24px":"16px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap", 
                          }}>
                            Price
                            <br/><span className="fw-medium" style={{fontSize:window.innerWidth>768?"20px":"16px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap", 
                                
                            }}>
                            {item?.panditPrice && "₹ "+item?.panditPrice+" |"}  {item?.panditPriceInDollar && item?.panditPriceInDollar+" $"}</span>
                          </p>
                          <div 
                          className=""
                          style={{
                              borderLeft: "1.6px solid white",
                              height: "90%",
                              alignSelf: "center",
                            }} />
                          </>}
                          
                          {item?.panditCount &&
                          <><p className="fw-semibold" style={{fontSize:window.innerWidth>768?"24px":"16px",
                             overflow: "hidden",
                             textOverflow: "ellipsis",
                             whiteSpace: "normal", 
                          }}>
                            Priest
                            <br/><span className="fw-medium" style={{fontSize:window.innerWidth>768?"20px":"16px",
                               overflow: "hidden",
                               textOverflow: "ellipsis",
                               whiteSpace: "nowrap", 
                            }}>{item?.panditCount}</span>
                          </p>
                          <div 
                          className=""
                          style={{
                              borderLeft: "1.6px solid white",
                              height: "90%",
                              alignSelf: "center",
                            }} />
                            </>}
                          {item?.callingTag&&
                          <><p className="fw-semibold d-none d-sm-block " style={{fontSize:window.innerWidth>768?"24px":"16px",
                             overflow: "hidden",
                             textOverflow: "ellipsis",
                             whiteSpace: "nowrap", 
                          }}>
                            Time
                            <br/><span className="fw-medium" style={{fontSize:window.innerWidth>768?"20px":"16px",
                               overflow: "hidden",
                               textOverflow: "ellipsis",
                               whiteSpace: "nowrap", 
                            }}>{item?.callingTag}</span>
                          </p>
                          <div className="d-none d-sm-block"
                          style={{
                              borderLeft: "1.6px solid white",
                              height: "90%",
                              alignSelf: "center",
                            }} />
                            </>}
                         
                         {item?.headerColor && <p className="fw-semibold" style={{fontSize:window.innerWidth>768?"24px":"16px",
                             overflow: "hidden",
                             textOverflow: "ellipsis",
                             whiteSpace: "nowrap", 
                             }}>
                            Date      
                            <br/><span className="fw-medium" style={{fontSize:window.innerWidth>768?"20px":"16px",
                               overflow: "hidden",
                               textOverflow: "ellipsis",
                               whiteSpace: "nowrap", 
                            }}>{moment(item?.headerColor,"YYYY-MM-DD").format("D MMM")}</span>
                          </p>}
                    </div>
                    <div
                      className="bg-primary rounded text-center px-auto text-white d-flex align-items-center justify-content-center mt-auto mb-md-4  mb-3 fw-semibold"
                      onMouseEnter={(e) => (e.target.style.opacity = 0.9)}
                      onMouseLeave={(e) => (e.target.style.opacity =1)}
                      style={{
                        // width: "110px",
                        fontSize: window.innerWidth > 768 ?'20px':'18px',
                        fontFamily: 'monospace',
                        height:  window.innerWidth > 768 ?"44px":"35px",
                        width: window.innerWidth > 768 ? "242px" : '132px',
                        cursor: "pointer",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        navigate("productdetail/" + item?.itemId);
                        localStorage.setItem(MYPRODUCT, JSON.stringify(item));
                      }}
                    >
                      Book Puja
                    </div>
               </div>
            </div>
          
        </div>
  </div>
  </>
  );

}

function BannerImage({ item }) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);
  // const [items, setItems] = useState(defaultBannerData.defaultItems);
  const [items, setItems] = useState(item);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  // console.log("items", items);
  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);


  useEffect(() => {
    if (item) {
      setItems(item);
      setCurrentIndex(0);
    }
  }, [item]);



  const startAutoSwitch = () => {
    if (intervalId) {
      clearInterval(intervalId); // Clear existing interval to avoid stacking intervals
    }
    const id = setInterval(() => {
      if (!isHovered) { // Only auto-switch if not hovered
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }
    }, 3500);
    setIntervalId(id); // Store the interval ID to clear it later
  };

  // Stop auto-switching items
  const stopAutoSwitch = () => {
    if (intervalId) {
      clearInterval(intervalId); // Clear the existing interval
      setIntervalId(null);
    }
  };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  //   stopAutoSwitch();
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  //   setTimeout(startAutoSwitch, 10);
  // };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    stopAutoSwitch();
    setTimeout(startAutoSwitch, 10);

  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex === 0 ? items.length - 1 : prevIndex - 1);
    stopAutoSwitch();       // Stop and restart the interval
    setTimeout(startAutoSwitch, 10);
  };

  const handleClick = (index) => {
    setCurrentIndex(index);
    stopAutoSwitch();
    setTimeout(startAutoSwitch, 10);
  };
  useEffect(() => {
    startAutoSwitch();
    return () => stopAutoSwitch(); // Clean up when component unmounts
  }, [items]);

  const currentItem = items[currentIndex];

  return (
    <div
      className="pt-5 pb-4"
      style={{
        backgroundImage: `url('/art/Swastik.png')`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* Conditional rendering for the header */}

      {showEdit && (
        <div className="d-sm-flex  mt-5">

          <Link
            className="btn btn-outline-primary  mx-md-3 ms-2 mt-1"
            style={{ width: 'auto', height: "36px" }}
            to={"/addcatitem"}
            replace
            onClick={() =>
              localStorage.setItem(CATITEM, JSON.stringify(currentItem))
            }

          >
            <FontAwesomeIcon icon="fa-solid  fa-pen-to-square" />
            Edit
          </Link>

          <p
            className="fs-2 text-black  mx-md-3 mx-2 mb-0"
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: window?.innerWidth > 500 ? '500px' : '280px',
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
            }}
          >
            {currentItem?.title}
          </p>

        </div>
      )}

      <div className="container-fluid px-md-3 px-2  mt-5 ">
        <div className="row">
          {/* Left Section */}
          <div className="col-md-5 ">
            {/* Month/Year/Time Buttons for small screens */}
            {/* <div className="d-flex justify-content-between d-md-none  text-center text-white rounded-3 mb-4 "
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "rgba(186, 186, 186, 0.6)",
              }}
            >
              <button className="btn ">Month <span>(Nov)</span></button>
              <button className="btn btn-secondary "
                style={{
                  height: "40px",
                }}
              >Year</button>
              <button className="btn ">Day</button>
            </div> */}

            <div onClick={() => {
              localStorage.setItem(MYPRODUCT, JSON.stringify(currentItem))
              navigate("productdetail/" + currentItem?.itemId,)
            }}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            >
              <img
                src={`${BASE_URL_IMG + currentItem?.iconURL}`}
                alt="..."
                width="1000"
                height="0"
                style={{ ...mainImageStyles, position: "relative", zIndex: 2 }}  // Ensure main image is on top
                className="img-fluid   position-relative"
              />
            </div>
            <img
              src={torans}
              alt="..."
              width="190"
              height="278"
              className="d-none d-md-block"
              style={{ ...overlayImageStyles, position: "absolute", zIndex: 1 }}
            />
          </div>

          {/* Right Section */}
          <div className="col-md-7  d-flex flex-column align-content-between ">
            {/* Month/Year/Time Buttons for larger screens */}
            {/* <div
              className="d-none d-md-flex justify-content-between  rounded-3 mx-auto mb-4"
              style={{
                width: "70%",
                height: "44px",

                // paddingRight: "50px",
                // paddingRight: "150px",
                backgroundColor: "rgba(186, 186, 186, 0.6)",
              }}
            >
              <button className="btn text-white">Month (<span className=" text-black"> Nov </span>)</button>
              <button
                className="btn text-white "
                style={{
                  backgroundColor: "rgba(150, 150, 150, 0.6)",
                  width: "110px",
                  height: "44px",
                }}
              >
                Year (<span className=" text-black"> 024 </span>)
              </button>
              <button className="btn text-white">Day (<span className=" text-black"> Sun </span>)</button>
            </div> */}

            {/* Content Block */}
            <div
              onClick={() => {
                localStorage.setItem(MYPRODUCT, JSON.stringify(currentItem))
                navigate("productdetail/" + currentItem?.itemId,)
              }
              }
              // onClick={() => handlePress(currentItem)}
              className="position-relative  rounded-3   text-md-start  my-sm-0 my-3"
              style={{ cursor: 'pointer', height: window.innerWidth > 500 ? "337px" : "" }}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            >
              <img
                src={havan}
                alt="..."
                className="position-absolute rounded-3  h-100 "
                style={{ objectFit: "cover", width: "100%" }}
              />
              <div className="position-relative  px-sm-5 px-3 py-sm-4 py-2 ">
                <h1
                  className="text-black display-6 fw-semibold "
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,

                  }}
                >
                  {currentItem?.title}
                </h1>
                <p
                  className="fs-5"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "rgba(0, 0, 0, 0.54)",
                    WebkitLineClamp: window.innerWidth > 500 ? 7 : 4,
                  }}
                >
                  {currentItem?.subTitleID.replace(/(<([^>]+)>)/gi, "")}
                </p>
              </div>
            </div>

            {/* Button at the end */}
            <div className="d-flex justify-content-center align-items-center mt-auto ">
              <div
                className="d-flex justify-content-between align-items-center "
                style={{ width: window.innerWidth > 550 ? '77%' : "65%", gap: '10px' }}
              >
                <p onClick={handlePrev} className="bg-secondary px-2 rounded-2 text-white" style={{ cursor: 'pointer', margin: 0 }}>
                  <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                </p>
                <div className="d-flex no-scrollbar " style={{ overflowX: 'auto', gap: "10px" }}>
                  {items.map((item, idx) => (
                    <p
                      key={idx}
                      id={`item-${idx}`}
                      className={`px-2 text-white text-center rounded-2 ${idx === currentIndex ? 'active' : ''}`}
                      style={{
                        backgroundColor: idx === currentIndex ? '#666361' : '#bfb9b5',
                        transition: 'background-color 0.3s ease',
                        cursor: 'pointer',
                        margin: 0,
                        minWidth: '70px'
                      }}
                      onClick={() => handleClick(idx)}
                    >
                      {item?.bgcolor ? item?.bgcolor : "__ Nov"}
                    </p>
                  ))}
                </div>
                <p onClick={handleNext} className="bg-secondary px-2 rounded-2 text-white" style={{ cursor: 'pointer', margin: 0 }}>
                  <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                </p>
              </div>

              <div
                className="bg-primary rounded text-center fs-sm-5 fs-6 px-sm-3 px-2 text-white d-flex align-items-center justify-content-center"
                style={{
                  // width: "110px",
                  fontFamily: 'monospace',
                  height: "44px",
                  width: window.innerWidth > 500 ? "120px" : '100px',
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
                onClick={() => {
                  navigate("productdetail/" + currentItem?.itemId);
                  localStorage.setItem(MYPRODUCT, JSON.stringify(currentItem));
                }}
              >
                Book Puja
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Choose By God Section */}
      <div className="text-center" style={{ marginTop: window?.innerWidth > 760 ? 80 : 40, }}>
        <h1 className="fs-1 fw-semibold" style={{ fontFamily: 'sans-serif' }}>Choose Puja By God</h1>
        <div className="d-flex overflow-auto py-4 px-3 gap-4 justify-content-between no-scrollbar">
          {gods.godpic.map((item, index) => (
            <div key={index} className="flex-shrink-0">
              <ChooseByGod item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

}

const ChooseByGod = ({ item }) => {

  return (
    <div className="text-center" style={{ width: '120px' }}> {/* Set width for uniform sizing */}
      <img
        src={item.image}
        alt={"..."}
        className="img-fluid rounded-circle"
        style={{ objectFit: "cover", width: "100px", height: "100px" }} // Adjust as needed
      />

    </div>
  );
};

