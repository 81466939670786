import React, { useState, useEffect } from "react";
import data from "../utils/data";
import { GOOGLE_DRIVE_URL, LOGO_DRIVE_ICON} from "../Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// function Contact() {
const Contact = () => {
  const [info, setInfo] = useState(null);
  const [social, setSocial] = useState(null);

  useEffect(() => {
    setInfo(data.info);
    setSocial(data.social);
  }, []);

  return (
    <div className="container h-50 pt-5 mt-5">
      <form className="row d-flex justify-content-center align-items-center h-100">
        <div className="Auth-form-content">
          {info !== null && (
            <div class="card-body">
              <div class="d-flex flex-column align-items-center text-center">
                <img
                  className="p-1 bg-primary"
                  src="https://shreesarvshakti.com/img/1.%20Pandit.png"
                  alt="Girl in a jacket"
                  width="100%"
                  
                ></img>

                <div class="mt-3">
                  <h4>Shree Sarv Shakti</h4>
                  <p className="mb-2 text-dark  small ">
                    Shree Sarv Shakti is a leading Uttar Pradesh, India. based
                    Astrologer, Numerologist, Palmist and Vaastu Consultant
                  </p>
                </div>
              </div>

              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                    <span> </span>
                    Gmail
                  </h6>
                  <a className="ms-auto link-dark">shaktishreesarv@gmail.com</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    <FontAwesomeIcon icon="fa-brands fa-square-whatsapp" />
                    <span> </span>
                    whatsapp
                  </h6>
                  <a
                    href="https://wa.me/8543981244"
                    className="ms-auto link-dark"
                  >
                    917985082508
                  </a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    <FontAwesomeIcon icon="fa-brands fa-twitter" />
                    <span> </span>
                    Twitter
                  </h6>
                  <a
                    href="https://twitter.com/ShreeSarvShakt1"
                    className="ms-auto link-dark"
                  >
                    ShreeSarvShakt
                  </a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                    <span> </span>
                    Instagram
                  </h6>
                  <span class="text-secondary">
                    <a
                      href="https://www.instagram.com/shreesarvshakti/"
                      className="ms-auto link-dark"
                    >
                      Shreesarvshakti
                    </a>
                  </span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    <FontAwesomeIcon icon="fa-brands fa-facebook" />
                    <span> </span>
                    Facebook
                  </h6>
                  <a
                    href="https://www.facebook.com/profile.php?id=100086725784050"
                    className="ms-auto link-dark"
                  >
                    Shreesarvshakti
                  </a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                  <h6 class="mb-0">
                    <FontAwesomeIcon icon="map-marker" />
                    <span> </span>
                    AddAddress
                  </h6>
                  <div>
                    Address shree saccha baba nagar arail naini prayagraj uttar
                    pradesh Pine code 211008
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

// const Contact = () => {
//     const [info, setInfo] = useState(null);

//     useEffect(() => {
//         setInfo(data.info);
//     }, []);

//     return (
//         <>
//             <h2>Contact Us</h2>
//             {
//                 info !== null &&
//                 <div  className="container mt-5 py-4 px-xl-5">
//                     <p>Please contact with me if any issues...</p>
//                     <p>
//                         Email - {info.email} <br />
//                         Phone No - {info.phone_no} <br />
//                     </p>
//                 </div>
//             }
//         </>
//      );
// }

export default Contact;
