import React from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import swal from "sweetalert";

import {
  SESSION_ID,
  CUSTOMER_ID,
  BASE_URL,
  GetOrderInfosByDetails,
  ACCESS_TOKEN,
  USER_ROLE,
  ROLE,
  DEBUG_LOG_ENABLED,
  getOrderInfoByDate,
  ADDRESSLIST,
  getCustomerAddressByUserId,
} from "../Constant";
import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { increment } from "../actions";
import GetOrderService from "./GetOrderService";
import RemoveOrderHistoryService from "./RemoveOrderHistoryService";
import AccountMenu from "../profile/account-menu";
import OrderHistoryItem from "../order_history/OrderHistoryItem";
import axios from "axios";
import moment from "moment";

function OrderHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderItemList, setOrderItemList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  let [showEdit, setShowEdit] = useState(false);
  let [Loader, setLoader] = useState(false);
  let [Enddate, setenddate] = useState(moment().format("YYYY-MM-DD"));

  // const getAddressLists = async () => {
  //   try{
  //     const customerData = JSON.stringify({
  //       pubInfo: {
  //         sessionId: localStorage.getItem(SESSION_ID),
  //       },
  //       request: {
  //         busiParams: {
  //           customerId: localStorage.getItem(CUSTOMER_ID),
  //         },
  //         isEncrypt: false,
  //         transactionId: "897987987989",
  //       },
  //     });

  //     let response = await axios.post(
  //       BASE_URL + getCustomerAddressByUserId,
  //       customerData,
  //       {
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  //           "Access-Control-Allow-Headers": "Content-Type",
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
  //         },
  //       }
  //     );
  //     setAddressList(filterByAddressId(response?.data?.respData?.respMsg));
  //         localStorage.setItem(
  //           ADDRESSLIST,
  //           JSON.stringify(response?.data?.respData?.respMsg));
  //   }catch(err) {
  //       if (err.response.status == 401) {
  //         navigate("/login");
  //       } else {
  //         swal("data : " + err);
  //         if (DEBUG_LOG_ENABLED) {
  //           console.log(err);
  //         }
  //       }
  //     };
  // };

  const filterByAddressId = (addressItemList, addressId) => {
    const addressItem = addressItemList.filter((item) => item.id === addressId);
    return addressItemList;
  };
  const getOrdertLists = async () => {
    try {
      var userType = 0;
      var custmId = localStorage.getItem(CUSTOMER_ID);
      const userRole = localStorage.getItem(USER_ROLE);


      if (userRole?.includes(ROLE)) {
        custmId = "";
        userType = 1;
      } else {
        custmId = localStorage.getItem(CUSTOMER_ID);
        userType = 0;
      }


      const orderListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: "",
            customerId: custmId,
            user: userType,
            date: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await GetOrderService.create(orderListData);
      let response = await axios.post(
        BASE_URL + GetOrderInfosByDetails,
        orderListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // swal("data : "+response.data.respData.respMsg.sessionId);
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      if (DEBUG_LOG_ENABLED) {
        console.log("orderItemList : " + orderItemList);
      }
      //swal("data : "+catItemList.length);
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const getOrderDate = async (startdate, enddate) => {
    try {
      const getOrderByDate = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            startDate: startdate ?? moment().format("YYYY-MM-DD"),
            endDate: enddate ?? moment().format("YYYY-MM-DD"),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + getOrderInfoByDate,
        getOrderByDate,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      //  console.log(response.data.respData.respMsg.morderInfoList);
    } catch (error) {
      swal(error);
    }
  };







  useEffect(() => {
    const addressList = localStorage.getItem("addressList");
    const addressItemList = JSON.parse(addressList);
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
      getOrderDate();
    } else {
      getOrdertLists();
    }
    // if (addressItemList?.lenght === 0 || addressItemList === null) {
    //   getAddressLists();
    // } else {
    //   setAddressList(addressItemList);
    // }

    // ( showEdit ? getOrderDate() : getOrdertLists())



  }, []);






  const [dates, setDates] = useState({
    startdate: "",
    endtdate: "",
  });

  const handleChange = (e) => {
    setenddate(dates.endtdate);
    const value = e.target.value;
    setDates({ ...dates, [e.target.name]: value });
  };
  const getOrderHistoryByDates = () => {


    if (
      //  customer.username === "" ,

      (dates.endtdate === "", dates.startdate === "")
    ) {
      swal(" Please entery data  ");
    } else {
      // loginData(customer.username, customer.password, customer.idToken);

      getOrderDate(dates.startdate, dates.endtdate);
    }
  };
  return (
    <div className="container mt-5  px-xl-5" style={{ paddingBottom: 200 }}>
      <div className="container py-4">
        <div className="row g-3">
          <div className="col-lg-3">
            <AccountMenu current="order-history" />
          </div>
          <div className="col-lg-9">
            {showEdit ? (
              <div className="row g-2 mb-4">
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      name="startdate"
                      type="date"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={dates.startdate}
                      required
                      aria-required="true"
                    />
                    <label htmlFor="floatingInputGrid">startDate</label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      name="endtdate"
                      type="date"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      // value={Enddate}
                      value={dates.endtdate}
                      required
                      aria-required="true"
                    />
                    <label htmlFor="floatingInputGrid">
                      EndtDate (current date)
                    </label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <button
                      onClick={getOrderHistoryByDates}
                      type="submit"
                      className="btn btn-primary m-2 py-2"
                    >
                      Submit
                      {Loader && (
                        <div
                          className="spinner-border text-light mx-1 "
                          style={{ height: 20, width: 20 }}
                          role="status"
                        ></div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {orderItemList?.length === 0 ? (
              <div className="container py-4">
                You have no order currently, Please add the item to your cart.
              </div>
            ) : (
              <div>
                {orderItemList?.map((it) => (
                  <OrderHistoryItem
                    id={it.orderNum}
                    product={it}
                    //cancel={it.catType}
                    oldaddressData={filterByAddressId(
                      addressList,
                      it.addressId
                    )}
                    addressData={it?.address}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
