import http from "../services/http-common";

class SearchApi {
  getAll() {
    return http.get("findSearchByItemTitle");
  }

  get(id) {
    return http.get(`findSearchByItemTitle/${id}`);
  }

  create(data) {
    return http.post("findSearchByItemTitle", data);
  }

  update(id, data) {
    return http.put(`findSearchByItemTitle/${id}`, data);
  }

  

 
}

export default new SearchApi();