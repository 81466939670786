import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import Header2 from "./Header2";

function Template(props) {
  return (
    <>
      <Header/>
      {/* <Header2/> */}
      <Content>{props.children}</Content>
      <Footer />
    </>
  );
}

export default Template;
