import http from "../services/http-common";

class RemoveCartItemService {
  getAll() {
    return http.get("removeCustomerAddress");
  }

  get(id) {
    return http.get(`removeCustomerAddress/${id}`);
  }

  create(data) {
    return http.post("removeCustomerAddress", data);
  }

  update(id, data) {
    return http.put(`removeCustomerAddress/${id}`, data);
  }

  delete(id) {
    return http.delete(`removeCustomerAddress/${id}`);
  }

  deleteAll() {
    return http.delete(`removeCustomerAddress`);
  }

  findByTitle(title) {
    return http.get(`removeCustomerAddress?title=${title}`);
  }
}

export default new RemoveCartItemService();
