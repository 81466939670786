import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState, useEffect } from "react";
import { CUSTOMER_ID, SESSION_ID } from "../Constant";
import { Link, useNavigate } from "react-router-dom";

function OrderHistoryItem({ id, product, cancel,oldaddressData, addressData }) {
  var address =""
  if(addressData!==""){
   address = JSON.parse(addressData);
  }
   
  const navigate = useNavigate();
  return (
    <div className="card border-0 shadow-sm mb-3">
      <div className="card-header py-3 bg-white">
        <div className="row">
          <div className="col d-flex">
            <span className="fw-semibold h5 my-auto">Order ID: {id}</span>
          </div>
          <div className="col-auto">
            <Link
              className="btn btn-sm btn-outline-primary"
              to="/orderhistorydetail"
            >
              View Detail
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row gx-2 gy-3">
          <div className="col-md-5">
            <h6 className="fw-bold">Shipping Address</h6>
            {address === "" ? (
              <div className="vstack text-dark small">
                <span className="h6">{oldaddressData[0]?.name}</span>
                <span>
                  {oldaddressData[0]?.id +
                    "" +
                    oldaddressData[0]?.addressLine1 +
                    "" +
                    oldaddressData[0]?.addressLine2}
                </span>
                <span>
                  {oldaddressData[0]?.city +
                    " " +
                    oldaddressData[0]?.state +
                    " " +
                    oldaddressData[0]?.country +
                    " " +
                    oldaddressData[0]?.pincode}
                </span>
                <span>{"Mobile No: " + oldaddressData[0]?.mobileNo}</span>
              </div>
            ) : (
              <div className="vstack text-dark small">
                <span className="h6">{address?.name}</span>
                <span>
                  {address?.addressLine1 + "" + address?.addressLine2}
                </span>
                <span>
                  {address?.city +
                    " " +
                    address?.state +
                    " " +
                    // address?.country +
                    " " +
                    address?.pincode}
                </span>

                <span>{"Mobile No: " + address?.mobileNo}</span>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <h6 className="fw-bold">Payment Method</h6>
            <div className="text-success">
              {cancel ? (
                <>
                  <span className="fw-bold">
                    <FontAwesomeIcon
                      icon={["fas", "money-bill-wave"]}
                      size="lg"
                    />
                  </span>
                  <span className="ms-2 small">Cash on delivery</span>
                </>
              ) : (
                <>
                  {/* <span className="fw-bold">
                    <FontAwesomeIcon icon={["fab", "cc-visa"]} size="lg" />
                  </span> */}
                  <span className="ms-2 small">{product.paymentId}</span>
                </>
              )}
            </div>
            <div>
              {product.currency === "USD"
                ? "Subtotal: " + "$" + product.amount
                : "Subtotal: " + "₹" + product.amount}
            </div>


            <div className="fw-semibold">
              {product.currency === "USD"
                ? "Subtotal: " + "$" + product.amount
                : "Total: " + "₹" + product.amount}
            </div>
          </div>
          <div className="col-md-3">
            <h6 className="fw-bold">Status</h6>
            <div className={cancel ? "text-danger" : "text-success"}>
              <span className="fw-semibold">
                {product.currency === "USD" ? (
                  "PLACED"
                ) : (
                  <>
                    {product.orderStatus === 2
                      ? "CANCELLED"
                      : product.orderStatus === 3
                      ? "COMPLETED"
                      : product.orderStatus === 0
                      ? "PAYMENT PENDING"
                      : product.orderStatus === 1
                      ? "PLACED"
                      : product.orderStatus === -1
                      ? "PAYMENT FAILED"
                      : ""}
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer small border-0 py-3 text-muted">
        Order Date & Time :{product.date}:{product.startTime}
        {/* Order Date: {moment(Date(product.date)).format("DD-MMM-YYYY")} */}
      </div>
    </div>
  );
}

export default OrderHistoryItem;
