import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ABOUT_US_VIEW, BASE_URL_IMG } from "../Constant";
import parse from "html-react-parser";
import aboutPG from "../images/images/aboutbg.png";
const AboutUs = () => {
  const catData = localStorage.getItem("categorydata");
  const product = JSON.parse(catData);
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => { }, []);
  const navigate = useNavigate();

  return (
    <>
      {product[0]?.items?.map((it) =>
      (<div className="pt-4">
        <div>
          <img
            src={aboutPG}
            alt="Background"
            width={1280}
            height={106}
            className="img-fluid w-100"
          />
          <div className="d-md-flex gap-3 mt-3">
            <img
              src={BASE_URL_IMG + it.iconURL}
              alt="Panditji"
              width={346}
              height={295}
              className="mt-n4 mt-md-n5 ms-md-3 shadow rounded-3 ms-2 "
              style={{ width: '256px', maxWidth: '346px', height: 'auto', }}
            />
            <p className="mt-4 ms-3 text-secondary">
              <span className="fw-bold fs-4 text-dark">Pandit Raghvendra Shastri Ji</span>, the owner
              and member of Sree Sarv Shakti, assembled a group of pandits with experience and faith in
              God.<br />
              The sacred rituals of puja generate a powerful energy that purifies both the mind and the physical surroundings. The vibrations created by chanting mantras, lighting lamps, and offering flowers and incense cleanse negative energies, replacing them with a vibrant, positive atmosphere. This sacred space promotes feelings of joy, optimism, and love, creating a welcoming environment for family and guests alike.


            </p>
          </div>
          <div className="m-4 mt-md-5">
            <div className="d-md-flex justify-content-between mb-3">
              <h1 className="display-4">About Shree Sarv Shakti</h1>
              {/* Uncomment and integrate HinEngButton if necessary */}
              {/* <HinEngButton onPress={} /> */}
            </div>
            <p className="text-secondary">
              {parse(`${it.subTitle}`)}
            </p>
          </div>
        </div>
      </div>
      ))}

    </>
  );
};

export default AboutUs;
