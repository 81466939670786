import http from "../services/http-common";

class CatItemService {
  saveCategoryItem(data) {
    return http.post("addCategoryItem", data);
  }

  deleteCategoryItem(data) {
    return http.post("deleteItem", data);
  }

  updateCategoryItem(id, data) {
    return http.put(`addCategoryItem/${id}`, data);
  }
}

export default new CatItemService();
