import React from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

export default function Paypal() {

   const location = useLocation();
   const redirectUrl = location.state;
// swal(redirectUrl);
  return (
    <div className="justify-content-center container p-2 mt-5 ">
      <div
        class="    rounded-4 border border-dark mb-2    "
        style={{
          // minWidth: 290,
          // minHeight: 200,
          width: "100%",

          height: 650,
        }}
      >
        {redirectUrl === ""  || redirectUrl===null ? (
          <div class="position-absolute top-50 start-50 translate-middle">
            <div
              class="spinner-border text-light  "
              role="status"
              style={{ height: 100, width: 100 }}
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <iframe
            width="100%"
            height="100%"
            src={redirectUrl}
            allowFullScreen
            className="rounded-4 d-block   "
            loading="lazy"
          ></iframe>
        )}
      </div>
    </div>
  );
}
