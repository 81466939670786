import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import TextCardItemView from "./TextCardItemView";
import { Link } from "react-router-dom";

function Headline({ item, navigate, showEdit, nama }) {
  const data = () => {
    //  navigate("/itemsviewmore");
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };
  return (
    <div className="d-flex justify-content-between align-items-center mt-md-5 mt-3 py-3 pe-2">
    {/* Title Block */}
    <div
      className="col px-4 "
      style={{
        // backgroundColor: 'rgba(247, 95, 1, 0.1)',
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        // fontFamily:'sans-serif'
      }}
    >
      <p className="h3 fw-medium" style={{ margin: 0 ,
           display: '-webkit-box',
           WebkitBoxOrient: 'vertical',
           WebkitLineClamp: 2,
           overflow: 'hidden',
       }}>{item?.title}</p>
    </div>
  
    {/* Links Block */}
    <div className="ms-auto d-flex align-items-center">
      <Link
        to={"/itemsviewmore"}
        className="btn btn-light text-primary text-truncate me-2"
        style={{
          fontWeight: 700,
          margin: 0,
        }}
        onClick={() => data()}
      >
        See all
        <FontAwesomeIcon
          icon="fa-solid fa-greater-than"
          className="ps-2"
          fade
        />
      </Link>
      {showEdit ? (
        <Link
          to={"/addcat"}
          className="btn btn-outline-primary"
          onClick={() =>
            navigate(localStorage.setItem("catData", JSON.stringify(item)))
          }
          type="submit"
        >
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
          Edit
        </Link>
      ) : null}
    </div>
  </div>
  
  );
}

export default Headline;
