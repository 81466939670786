import http from "../services/http-common";

class CartService {

    addToCart(data){
     return http.post("addToCart",data);
   } 

   removeFromCart(data){
    return http.post("removeFromCart",data);
  } 
}
export default new CartService();